import { CampaignHeroParagraph, DonateHeroParagraph, DonateParagraph, HeroParagraph, ShortHeroParagraph, TallHeroParagraph, VideoHeroParagraph, VoucherHeroParagraph, VoucherParagraph } from '@/__generated__/graphql';
import { getLanguage } from '@/lib/config';
import { imageLoaderResponsive } from '@/theme/lib';
import { useMobile } from '@lib/hooks/useMobile';
import { useTablet } from '@lib/hooks/useTablet';
import Image from 'next/legacy/image';
import { FunctionComponent, useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { SwiperProps, SwiperSlideProps, useSwiper } from 'swiper/react';
import { SwiperModule } from 'swiper/types';
import StyledButton from '../Button';
import Donate from '../Donate';
import Voucher from '../Voucher';
import {
  StyledCampaignHero,
  StyledCircleHero,
  StyledCircleImage,
  StyledDonateHero,
  StyledDonationHeroWrapper,
  StyledDonationWrapper,
  StyledDonationWrapperInner,
  StyledHeroBox,
  StyledShortHero,
  StyledSlideController,
  StyledSliderImg,
  StyledTallHero,
  StyledTextOnlyHero,
  StyledVideoHero,
  StyledVoucherHero,
  StyledVoucherHeroWrapper,
  StyledVoucherWrapper
} from './Hero.styles';

function ShortHeroProps(props: ShortHeroParagraph): HeroType {
  let ctas = [];
  if (props.primaryCta?.url && props.primaryCta.title) {
    ctas.push({
      isPrimary: true,
      url: props.primaryCta.url,
      title: props.primaryCta.title,
      target: props.primaryCta.target || undefined,
    });
  }
  if (props.secondaryCta?.url && props.secondaryCta.title) {
    ctas.push({
      isPrimary: false,
      url: props.secondaryCta.url,
      title: props.secondaryCta.title,
      target: props.secondaryCta.target || undefined,
    });
  }
  return {
    type: props.backgroundImage?.xl ? (props.showAsCircle ? 'Circle' : 'Short') : 'TextOnly',
    title: props.title,
    background_url: {
      sm: props.backgroundImage?.sm || '',
      md: props.backgroundImage?.md || '',
      lg: props.backgroundImage?.lg || '',
      xl: props.backgroundImage?.xl || '',
      url: props.backgroundImage?.url || '',
    },
    hero_box: {
      title: props.title,
      eyebrow: props.eyebrow || '',
      cta: ctas,
    },
  };
}

function VideoHeroProps(props: VideoHeroParagraph): HeroType {
  let ctas = [];
  if (props.primaryCta?.url && props.primaryCta.title) {
    ctas.push({
      isPrimary: true,
      url: props.primaryCta.url,
      title: props.primaryCta.title,
      target: props.primaryCta.target || undefined,
    });
  }
  if (props.secondaryCta?.url && props.secondaryCta.title) {
    ctas.push({
      isPrimary: false,
      url: props.secondaryCta.url,
      title: props.secondaryCta.title,
      target: props.secondaryCta.target || undefined,
    });
  }
  return {
    type: 'Video',
    title: props.title,
    video_url: props.backgroundVideo.url,
    video_thumbnail: props.backgroundVideo.thumbnail,
    hero_box: {
      title: props.title,
      eyebrow: props.eyebrow || '',
      cta: ctas,
    },
    colour_theme: props.colourTheme,
  };
}

function TallHeroProps(props: TallHeroParagraph): HeroType {
  return {
    type: 'Tall',
    title: '',
    slides: props.slides.map((s) => {
      let ctas = [];
      if (s.primaryCta?.url && s.primaryCta?.title) {
        ctas.push({
          isPrimary: true,
          url: s.primaryCta.url,
          title: s.primaryCta.title,
          target: s.primaryCta.target || undefined,
        });
      }
      if (s.secondaryCta?.url && s.secondaryCta.title) {
        ctas.push({
          isPrimary: false,
          url: s.secondaryCta.url,
          title: s.secondaryCta.title,
          target: s.secondaryCta.target || undefined,
        });
      }

      return {
        background_url: {
          sm: s.backgroundImage?.sm || '',
          md: s.backgroundImage?.md || '',
          lg: s.backgroundImage?.lg || '',
          xl: s.backgroundImage?.xl || '',
          url: s.backgroundImage?.url || '',
        },
        hero_box: {
          title: s.title,
          eyebrow: s.eyebrow || '',
          cta: ctas,
        },
      };
    }),
  };
}

function CampaignHeroProps(props: CampaignHeroParagraph): HeroType {
  let ctas = [];
  if (props.primaryCta?.url && props.primaryCta.title) {
    ctas.push({
      isPrimary: true,
      url: props.primaryCta.url,
      title: props.primaryCta.title,
      target: props.primaryCta.target || undefined,
    });
  }
  return {
    type: 'Campaign',
    title: props.title,
    background_url: {
      sm: props.backgroundImage?.sm || '',
      md: props.backgroundImage?.md || '',
      lg: props.backgroundImage?.lg || '',
      xl: props.backgroundImage?.xl || '',
      url: props.backgroundImage?.url || '',
    },
    hero_box: {
      title: props.title,
      cta: ctas,
    },
  };
}

function DonateHeroProps(props: DonateHeroParagraph): HeroType {
  return {
    type: 'Donate',
    title: props.title,
    background_url: {
      sm: props.backgroundImage?.sm || '',
      md: props.backgroundImage?.md || '',
      lg: props.backgroundImage?.lg || '',
      xl: props.backgroundImage?.xl || '',
      url: props.backgroundImage?.url || '',
    },
    hero_box: {
      title: props.title,
    },
    ...(props.content ? {
      donationBlock: {
        __typename: 'DonateParagraph',
        id: props.content.id,
        content: props.content
      }
    } : {})
  };
}

function VoucherHeroProps(props: VoucherHeroParagraph): HeroType {
  return {
    type: 'Voucher',
    title: props.title,
    background_url: {
      sm: props.backgroundImage?.sm || '',
      md: props.backgroundImage?.md || '',
      lg: props.backgroundImage?.lg || '',
      xl: props.backgroundImage?.xl || '',
      url: props.backgroundImage?.url || '',
    },
    hero_box: {
      title: props.title,
    },
    ...(props.content
      ? {
          voucherBlock: {
            __typename: 'VoucherParagraph',
            id: props.content.id,
            content: props.content,
          },
        }
      : {}),
  };
}

export function CreateHeroProps(props: HeroParagraph): HeroType {
  switch (props.__typename) {
    case 'ShortHeroParagraph':
      return ShortHeroProps(props);

    case 'VideoHeroParagraph':
      return VideoHeroProps(props);

    case 'TallHeroParagraph':
      return TallHeroProps(props);

    case 'CampaignHeroParagraph':
      return CampaignHeroProps(props);

    case 'DonateHeroParagraph':
      return DonateHeroProps(props);

    case 'VoucherHeroParagraph':
      return VoucherHeroProps(props);

    default:
      return ShortHeroProps(props);
  }
}

function SlideController({ numOfSlides, isMobile }: { numOfSlides: number; isMobile?: boolean }) {
  const swiper = useSwiper();
  const [isRunning, setIsRunning] = useState(swiper.autoplay.running);
  const [activeIndex, setActiveIndex] = useState(swiper.activeIndex);

  swiper.on('activeIndexChange', (s) => {
    setActiveIndex(s.activeIndex);
  });

  swiper.on('autoplayStart', (s) => {
    setIsRunning(true);
  });

  swiper.on('autoplayStop', (s) => {
    setIsRunning(false);
  });

  swiper.on('touchStart', (s, e) => {
    if (isMobile) {
      e.preventDefault();
      swiper.autoplay.stop();
      setIsRunning(false);
    }
  });

  swiper.on('touchEnd', (s, e) => {
    if (isMobile) {
      e.preventDefault();
      swiper.autoplay.start();
      setIsRunning(true);
    }
  });

  return (
    <StyledSlideController className="slide-controller">
      <div className="slide-controller__wrapper">
        <div className="slide-controller__container">
          <span
            className={`slide-controller__play ${
              isRunning ? 'slide-controller__play--active' : 'slide-controller__play--inactive'
            }`}
            key="stop"
            onClick={() => {
              if (isRunning) {
                swiper.autoplay.stop();
              } else {
                swiper.autoplay.start();
              }
            }}
          ></span>
          {[...Array(numOfSlides)].map((num, index) => (
            <span
              className={`slide-controller__slide ${
                activeIndex === index
                  ? 'slide-controller__slide--active'
                  : 'slide-controller__slide--inactive'
              }`}
              key={`slide${index}`}
              onClick={() => swiper.slideTo(index)}
            />
          ))}
        </div>
      </div>
    </StyledSlideController>
  );
}

interface BackgroundImage {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  url: string;
}
interface HeroBoxProps {
  title: string;
  eyebrow?: string;
  cta?: {
    isPrimary: boolean;
    url: string;
    title: string;
    target?: string | null;
  }[];
}
interface CampaignHeroBoxProps {
  title: string;
  cta?: {
    isPrimary: boolean;
    url: string;
    title: string;
    target?: string | null;
  }[];
}
interface DonateHeroBoxProps {
  title: string;
}

interface VoucherHeroBoxProps {
  title: string;
}

export interface HeroProps extends HeroBoxProps {
  type: 'TextOnly' | 'Circle' | 'Short' | 'Tall' | 'Video' | 'Campaign' | 'Donate' | 'Voucher';
}
interface TextOnlyHeroProps extends HeroProps {
  type: 'TextOnly';
  hero_box: HeroBoxProps;
}
interface CircleHeroProps extends HeroProps {
  type: 'Circle';
  background_url: BackgroundImage;
  hero_box: HeroBoxProps;
}
interface ShortHeroProps extends HeroProps {
  type: 'Short';
  background_url: BackgroundImage;
  hero_box: HeroBoxProps;
}
interface VideoHeroProps extends HeroProps {
  type: 'Video';
  video_url: string;
  video_thumbnail?: string | null;
  hero_box: HeroBoxProps;
  colour_theme: string;
}

interface TallHeroSlideProps {
  hero_box: HeroBoxProps;
  background_url: BackgroundImage;
}

interface TallHeroProps extends HeroProps {
  type: 'Tall';
  slides: TallHeroSlideProps[];
}

export interface CampaignHeroProps extends HeroProps {
  type: 'Campaign';
  background_url: BackgroundImage;
  hero_box: CampaignHeroBoxProps;
}

export interface DonateHeroProps extends HeroProps {
  type: 'Donate';
  title: string;
  background_url: BackgroundImage;
  hero_box: DonateHeroBoxProps;
  donationBlock?: DonateParagraph;
}

export interface VoucherHeroProps extends HeroProps {
  type: 'Voucher';
  title: string;
  background_url: BackgroundImage;
  hero_box: VoucherHeroBoxProps;
  voucherBlock?: VoucherParagraph;
}

export type HeroType =
  | TextOnlyHeroProps
  | CircleHeroProps
  | ShortHeroProps
  | VideoHeroProps
  | TallHeroProps
  | CampaignHeroProps
  | DonateHeroProps
  | VoucherHeroProps;

export default function Hero(props: HeroType) {
  const [isSlide, setIsSlide] = useState(() => props.type === 'Tall' && props.slides.length > 1);
  const [isReady, setIsReady] = useState(false);
  const isMobile = useMobile();
  const isTablet = useTablet();

  // Dynamically load swiper.
  const [swiperModules, setSwiperModules] = useState<SwiperModule[]>([]);
  const [Swiper, setSwiper] = useState<FunctionComponent<SwiperProps> | null>(null);
  const [SwiperSlide, setSwiperSlide] = useState<FunctionComponent<SwiperSlideProps> | null>(null);

  useEffect(() => {
    const getSwiperModules = async () => {
      const modules = await import('swiper');
      const { Autoplay, Pagination } = await import('swiper/modules');
      const swiperComponents = await import('swiper/react');

      setSwiperModules([Autoplay, Pagination]);
      setSwiper(swiperComponents.Swiper);
      setSwiperSlide(swiperComponents.SwiperSlide);
    };

    getSwiperModules();
  }, []);
  // Dynamically load swiper - End.

  const HeroBox = (props: HeroBoxProps) => {
    const language = getLanguage();
    return (
      <StyledHeroBox className="hero__box">
        <div className="hero__inner-wrapper">
          {props.eyebrow && <span dangerouslySetInnerHTML={{ __html: props.eyebrow }}></span>}
          <h1 lang={language !== 'en' ? language : undefined}>{props.title}</h1>
          {props.cta && props.cta.length > 0 && (
            <div className="hero__cta-wrapper">
              {props.cta.map((cta, i) => (
                <StyledButton
                  className={cta.isPrimary ? 'hero-primary' : 'hero-secondary'}
                  key={i}
                  href={cta.url}
                  primary={cta.isPrimary}
                  target={cta?.target || undefined}
                  lang={language !== 'en' ? language : undefined}
                >
                  {cta.title}
                </StyledButton>
              ))}
            </div>
          )}
        </div>
      </StyledHeroBox>
    );
  };

  useEffect(() => {
    setIsReady(true);
  }, []);

  const Content = (props: HeroType & { hero_box: HeroBoxProps }) => {
    return (
      <>
        {props.type === 'Video' && (
          <div className={`video-wrapper video-wrapper--${props.colour_theme}`}>
            <video
              autoPlay
              muted
              loop
              poster={props.video_thumbnail || undefined}
              playsInline
              id="heroVideo"
            >
              {isReady ? (
                <source src={props.video_url} type="video/mp4" />
              ) : (
                <source data-src={props.video_url} type="video/mp4" />
              )}
            </video>
          </div>
        )}
        <div className="hero__wrapper">
          <svg
            width="972"
            height="697"
            viewBox="0 0 972 697"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8" cy="-267" r="961.5" stroke="#C7F700" strokeWidth="5" />
          </svg>
          <HeroBox {...props.hero_box} />
          {props.type === 'Circle' && (
            <StyledCircleImage>
              <Image
                priority={true}
                src={props.background_url.url}
                alt={''}
                layout="fill"
                objectFit="cover"
                width={isMobile ? 360 : 840}
                height={isMobile ? 420 : 840}
                objectPosition="center"
                loader={imageLoaderResponsive(isMobile ? 360 : 840, isMobile ? 420 : 840)}
              />
            </StyledCircleImage>
          )}
        </div>
      </>
    );
  };

  const SwiperContent = (props: TallHeroProps) => {
    if (swiperModules.length > 0 && Swiper && SwiperSlide) {
      return (
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
          }}
          pagination={{
            clickable: true,
          }}
          touchStartPreventDefault={false}
          modules={[Autoplay, Pagination]}
          className="mySwiper"
        >
          {props.slides.map((slide, i) => (
            <SwiperSlide key={i}>
              <StyledSliderImg>
                <div className="hero__image">
                  <Image
                    priority={i === 0}
                    src={slide.background_url.url}
                    alt={''}
                    layout="fill"
                    objectFit="cover"
                    objectPosition="center"
                    width={isMobile ? 360 : isTablet ? 1024 : 1920}
                    height={isMobile ? 420 : 729}
                    loader={imageLoaderResponsive(
                      isMobile ? 360 : isTablet ? 1024 : 1920,
                      isMobile ? 580 : 1029
                    )}
                  />
                </div>
                <Content {...{ ...props, hero_box: slide.hero_box }} />
              </StyledSliderImg>
            </SwiperSlide>
          ))}
          <SlideController isMobile={isMobile} numOfSlides={props.slides.length} />
        </Swiper>
      );
    } else {
      return <>Loading...</>;
    }
  };
  switch (props.type) {
    case 'TextOnly':
      return (
        <StyledTextOnlyHero className="hero" role="banner" aria-label="Hero">
          <Content {...props} />
        </StyledTextOnlyHero>
      );
    case 'Short':
      return (
        <StyledShortHero className="hero" role="banner" aria-label="Hero">
          <div className="hero__image">
            <Image
              priority={true}
              src={props.background_url.url}
              alt={''}
              layout="fill"
              objectFit="cover"
              width={isMobile ? 360 : 1024}
              height={isMobile ? 420 : 729}
              objectPosition="center"
              loader={imageLoaderResponsive(
                isMobile ? 360 : isTablet ? 1024 : 1920,
                isMobile ? 420 : 729
              )}
            />
          </div>
          <Content {...props} />
        </StyledShortHero>
      );
    case 'Circle':
      return (
        <StyledCircleHero className="hero" role="banner" aria-label="Hero">
          <div className="hero__image">
            <Image
              priority={true}
              src={props.background_url.url}
              alt={''}
              layout="fill"
              objectFit="cover"
              width={isMobile ? 360 : isTablet ? 1024 : 1920}
              height={isMobile ? 580 : 1029}
              objectPosition="center"
              loader={imageLoaderResponsive(isMobile ? 360 : 1024, isMobile ? 420 : 729)}
            />
          </div>
          <Content {...props} />
        </StyledCircleHero>
      );
    case 'Video':
      return (
        <StyledVideoHero className="hero" role="banner" aria-label="Hero">
          <Content {...props} />
        </StyledVideoHero>
      );
    case 'Tall':
      return (
        <StyledTallHero className="hero" role="banner" aria-label="Hero">
          {isSlide ? (
            <SwiperContent {...props} />
          ) : (
            <>
              <div className="hero__image">
                <Image
                  priority={true}
                  src={props.slides[0].background_url.url}
                  alt={''}
                  layout="fill"
                  objectFit="cover"
                  width={isMobile ? 360 : isTablet ? 1024 : 1920}
                  height={isMobile ? 420 : 729}
                  objectPosition="center"
                  loader={imageLoaderResponsive(
                    isMobile ? 360 : isTablet ? 1024 : 1920,
                    isMobile ? 580 : 1029
                  )}
                />
              </div>
              <Content {...{ ...props, hero_box: props.slides[0].hero_box }} />
            </>
          )}
        </StyledTallHero>
      );
    case 'Campaign':
      return (
        <StyledCampaignHero className="hero" role="banner" aria-label="Hero">
          <div className="hero__image">
            <Image
              priority={true}
              src={props.background_url.url}
              alt={''}
              layout="fill"
              objectFit="cover"
              width={isMobile ? 360 : isTablet ? 1024 : 1920}
              height={isMobile ? 420 : 729}
              objectPosition="center"
              loader={imageLoaderResponsive(
                isMobile ? 360 : isTablet ? 1024 : 1920,
                isMobile ? 420 : 729
              )}
            />
          </div>
          <Content {...props} />
        </StyledCampaignHero>
      );
    case 'Donate':
      return (
        <StyledDonationHeroWrapper className="donation-hero">
          <StyledDonateHero className="hero" role="banner" aria-label="Hero">
            <div className="hero__image">
              <Image
                priority={true}
                src={props.background_url.url}
                alt={''}
                layout="fill"
                objectFit="cover"
                width={isMobile ? 360 : isTablet ? 1024 : 1920}
                height={isMobile ? 420 : 729}
                objectPosition="center"
                loader={imageLoaderResponsive(
                  isMobile ? 360 : isTablet ? 1024 : 1920,
                  isMobile ? 420 : 729
                )}
              />
            </div>
            <Content {...props} />
            {props.donationBlock?.content && (
              <StyledDonationWrapperInner>
                <Donate content={{...props.donationBlock.content}} referrer={'donation-campaign-hero'} />
              </StyledDonationWrapperInner>
            )}
          </StyledDonateHero>
          {props.donationBlock?.content && (
            <StyledDonationWrapper>
              <Donate content={{...props.donationBlock.content}} referrer={'donation-campaign-hero'} />
            </StyledDonationWrapper>
          )}
        </StyledDonationHeroWrapper>
      );
    case 'Voucher':
      return (
        <StyledVoucherHeroWrapper className="voucher-hero">
          <StyledVoucherHero className="hero" role="banner" aria-label="Hero">
            <div className="hero__image">
              <Image
                priority={true}
                src={props.background_url.url}
                alt={''}
                layout="fill"
                objectFit="cover"
                width={isMobile ? 360 : isTablet ? 1024 : 1920}
                height={isMobile ? 420 : 729}
                objectPosition="center"
                loader={imageLoaderResponsive(
                  isMobile ? 360 : isTablet ? 1024 : 1920,
                  isMobile ? 420 : 729
                )}
              />
            </div>
            <Content {...props} />
            {props?.voucherBlock?.content && (
              <StyledDonationWrapperInner>
                <Voucher content={props.voucherBlock.content} />
              </StyledDonationWrapperInner>
            )}
          </StyledVoucherHero>
          {props?.voucherBlock?.content && (
            <StyledVoucherWrapper>
              <Voucher content={props.voucherBlock.content} />
            </StyledVoucherWrapper>
          )}
        </StyledVoucherHeroWrapper>
      );
  }
}
